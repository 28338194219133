import React, { useState } from "react";
import { PanelWrapper } from "../../common/panels/PanelWrapper";
import FasterData from '../../../data/faster-scale.json'
import { InputPanel } from "../../common/inputs/InputPanel/InputPanel";
import { FasterScaleFilledTemplate } from "../FasterScaleFilledTemplate/FasterScaleFilledTemplate";
import { FasterInformation } from "../FasterInformation";

export const FasterScaleInputPanel = () => {

  const [stage, setStage] = useState('initial');
  const [data, setData] = useState({})

  if (stage === 'initial') {
    setStage(Object.keys(FasterData)[0])
    setData({})
    setStage('info')
  }

  const inputs = FasterData[stage]?.map((val, i) => {
    let defaultValue = false;
    if (data[stage] && data[stage][i]) {
      defaultValue = data[stage][i]
    }
    return {
      key: i,
      defaultValue,
      label: val,
      type: 'checkbox'
    }
  })

  if (stage === 'info') {
    return <PanelWrapper key={stage} className="bg-slate-300">
      <h2 className="px-6 py-4 uppercase bg-slate-500 rounded-t-lg text-lg text-white" >Information</h2>
      <FasterInformation />
      <button
        className="flex-shrink-0 text-lg border-2 py-1 px-8 mt-8 rounded bg-teal-300 hover:bg-teal-600 border-none hover:text-white"
        onClick={() => setStage(Object.keys(FasterData)[0])}
      >
        Start
      </button>
    </PanelWrapper>
  }

  if (!FasterData[stage] && stage !== 'final') return null;

  if (stage === 'final') {
    return <PanelWrapper key={stage} className="bg-slate-300">
      <h2 className="px-6 py-4 uppercase bg-slate-500 rounded-t-lg text-lg text-white" >Filled Template</h2>
      <FasterScaleFilledTemplate data={data} />
    </PanelWrapper>
  }

  return <PanelWrapper key={stage} className="bg-slate-300">
    <h2 className="px-6 py-4 uppercase bg-slate-500 rounded-t-lg text-lg text-white" >{stage}</h2>
    <InputPanel
      inputs={inputs}
      showCheckAll
      showClearAll
      cancelButtonText={Object.keys(FasterData).indexOf(stage) === 0 ? undefined : "Prev"}
      onCancel={() => {
        let nextStage = Object.keys(FasterData)[Object.keys(FasterData).indexOf(stage) - 1];
        setStage(!!FasterData[nextStage] ? nextStage : Object.keys(FasterData)[0])
        window.scrollTo({ top: 0, });
      }}
      submitButtonText={Object.keys(FasterData).indexOf(stage) === Object.keys(FasterData).length ? "Finished" : "Next"}
      onSubmit={(values) => {
        let nextStage = Object.keys(FasterData)[Object.keys(FasterData).indexOf(stage) + 1];
        setData(prev => ({ ...prev, [stage]: values }));
        setStage(!!FasterData[nextStage] ? nextStage : 'final')
        window.scrollTo({ top: 0, });
      }}
    />



  </PanelWrapper>
}