import React from "react";
import { PanelWrapper } from "../../common/panels/PanelWrapper";
import FasterData from '../../../data/faster-scale.json'
import { CapitalizeWord } from "../../../root/utils";

export const FasterScaleFilledTemplate = ({
  data = {},
}) => {

  const selectAll = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById('selection-pre'));
    window.getSelection().addRange(range);
  }

  const questions = [
    'How does it affect me? How do I feel in the moment?',
    'How does it affect the important people in my life?',
    'Why do I do this? What is the benefit for me?',
  ]

  let resultsString = "";

  Object.keys(FasterData).forEach(k => {
    let actions = [];
    Object.keys(data[k]).filter(index => data[k][index] === true).forEach(index => actions.push(FasterData[k][index]))
    resultsString += `${CapitalizeWord(k)} - ${actions.join("; ")}\n\n`

    resultsString += "Focus Action (pick one from above): ";
    if (actions.length === 1) resultsString += actions[0];
    resultsString += "\n\n";

    questions.forEach((q, i) => {
      resultsString += `${i + 1}. ${q}\n\n`
    })
    resultsString += "\n\n"
  })



  return <>
    <PanelWrapper className="bg-white rounded-b-lg">
      <p className="bg-slate-200 py-2 px-4">Copy and paste the template out into your word editor of choice to continue journaling</p>
      <button onClick={selectAll} className="flex-shrink-0 text-lg border-2 py-1 px-8 mx-3 my-4 rounded bg-teal-300 hover:bg-teal-600 hover:text-white"> Select All </button>
      <pre id="selection-pre" className="px-4" style={{ wordWrap: 'break-word', textWrap: 'wrap' }}>{resultsString}</pre>
    </PanelWrapper>
  </>
}