import BaseReducer from "./BaseReducer";

export default class ApplicationReducer extends BaseReducer {
  initialState = {
    // ui elements
    fasterValues: {}
  };

  ['SET_FASTER_VALUE'](state, action) {
    const { fasterKey, values } = action.payload;
    return {
      ...state,
      fasterValues: { ...state.fasterValues, [fasterKey]: values }
    }
  }
}