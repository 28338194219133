import React, { Fragment, useCallback, useRef, useState } from "react";
import { PanelWrapper } from "../../panels/PanelWrapper";
import { generateUUID } from "../../../../root/utils";

export const InputPanel = ({
  inputs,
  onSubmit,
  submitButtonText = "Submit",
  onCancel,
  cancelButtonText,
  showCheckAll,
  showClearAll,
}) => {

  const [inputValues, setInputValues] = useState({})
  const [editedInputs, setEditedInputs] = useState([])
  const internalUUIDRef = useRef(generateUUID())

  const [stage, setStage] = useState('initial');

  if (stage === 'initial') {
    setStage('loaded')

    setEditedInputs([]);

    let _inputValues = {}
    inputs.forEach(({ key, defaultValue }) => {
      _inputValues[key] = !!defaultValue ? defaultValue : undefined
      if (defaultValue) setEditedInputs(prev => [...prev, key])
    })
    setInputValues(_inputValues)

  }

  const _onSubmit = () => {
    let data = {};
    editedInputs.forEach(k => data[k] = inputValues[k])
    if (onSubmit) onSubmit({ ...data })
  }

  const renderInput = (inputProps) => {

    const { key, type, label, ...otherProps } = inputProps;

    const _onChange = (e) => {
      let _val = e.target.value
      if (type === 'checkbox') {
        _val = e.target.checked;
      }

      setInputValues(prev => ({ ...prev, [key]: _val }));
      setEditedInputs(prev => ([...prev, key]))
    }

    let _input = null;
    switch (type) {
      case "checkbox":
        _input =
          <label key={`${key}`} className="cursor-pointer">
            <input
              key={`${key}`}
              className="mx-4 cursor-pointer"
              type="checkbox"
              checked={inputValues[key] || false}
              onChange={_onChange}
              id={`${internalUUIDRef.current}-${key}`}
            />
            {label}
          </label>
        break;

      default:
        _input =
          <Fragment key={`${key}`}>
            {!!label && <span>{label}</span>}
            <input
              type="text"
              value={inputValues[key]}
              onChange={_onChange}
            />
          </Fragment>
    }

    return <PanelWrapper key={key}>
      {_input}
    </PanelWrapper>

  }

  if (stage === 'initial') return null;

  return <form className="bg-white shadow-md rounded-b-lg px-8 pt-6 pb-8 mb-4" onSubmit={_onSubmit}>

    {inputs.map(renderInput)}

    {showCheckAll &&
      <button
        type="button" // disable "submit"
        className="flex-shrink-0 text-lg border-2 py-1 px-8 mx-3 my-8 rounded"
        onClick={() => {
          let _x = {}
          inputs.forEach(({ key }) => _x[key] = true)
          setInputValues(prev => ({ ...prev, ..._x }))
          setEditedInputs(prev => ([...new Set([...prev, ...inputs.map(({ key }) => key)])]))
        }}
      >
        Check All
      </button>
    }
    {showClearAll &&
      <button
        type="button" // disable "submit"
        className="flex-shrink-0 text-lg border-2 py-1 px-8 mx-3 my-8 rounded"
        onClick={() => {
          let _x = {}
          inputs.forEach(({ key }) => _x[key] = false)
          setInputValues(prev => ({ ...prev, ..._x }))
          setEditedInputs(prev => ([...new Set([...prev, ...inputs.map(({ key }) => key)])]))
        }}
      >
        Clear All
      </button>
    }

    <PanelWrapper className="flex justify-end">
      {cancelButtonText &&
        <button type="button" onClick={onCancel} className="flex-shrink-0 text-lg border-2 py-1 px-8 mx-3 my-8 rounded hover:bg-teal-200">
          {cancelButtonText}
        </button>
      }
      <button type="button" onClick={_onSubmit} className="flex-shrink-0 text-lg border-2 py-1 px-8 mx-3 my-8 rounded bg-teal-300 hover:bg-teal-600 hover:text-white">
        {submitButtonText}
      </button>
    </PanelWrapper>

  </form>
}