const _debug = window.location.href.includes('localhost');

export const middlewareStoreLogger = (store) => (next) => (action) => {
  const activeLog = action && action.type && _debug;

  if (activeLog) {
    console.groupCollapsed(action.type);
    console.log(`prev state:`, { ...store.getState() });
    console.log('action:', action);
  }

  // perform action
  const result = next(action);

  if (activeLog) {
    console.log(`next state:`, { ...store.getState() });
    console.groupEnd();
  }

  return result;
}