import React from "react";
import { PanelWrapper } from "../common/panels/PanelWrapper";

export const FasterInformation = () => {

  return <PanelWrapper className="bg-white px-6 py-4 rounded-b-lg">

    <h3 className="pt-2 pb-1 uppercase text-lg" >What is the FASTER Scale?</h3>
    <p>FASTER is an acrostic for Forgetting priorities, Anxiety, Speeding up, Ticked off, Exhausted and Relapse.  It is one way to describes the progression of emotional states that span from a state of recovery to a state of relapse.</p>

    <h3 className="pt-2 pb-1 uppercase text-lg">What is this website?</h3>
    <p>This is an online, interactive adaptation of the form found <a href="https://puredesire.org/wp-content/uploads/2020/04/faster-scale-check-in-2017-1.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" >here</a>.  This tool simply helps generate a journal entry to be taken and filled in by you, using your own software (or printed).  This website stores nothing, and is subject to change at any point with or without notice.</p>

  </PanelWrapper>
}