/*

Class based reducers to allow inheritance on reducers for different derivations of redux

https://gist.github.com/codeBelt/d89eda0e80eca7274566cf3f484b5b38#file-showsreducer-js

*/

export default class BaseReducer {
  initialState = {};

  // function passed to combineReducers in app root when initializing reducers

  reducer = (state = this.initialState, action) => {
    const method = this[action.type];

    if (!method || action.error) return state; // no change to state

    return method.call(this, state, action);
  };
}