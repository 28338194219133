import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ApplicationRoot } from './root/ApplicationRoot';
import { middlewareStoreLogger } from './redux/middleware/ReduxActionsLogger';
import { thunk } from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, legacy_createStore } from 'redux';
import AppReducers from './redux/reducers/_Reducers';
import { Provider } from 'react-redux';

document.title = "Faster Scale";

// configure redux
const configureStore = () => {
  const middlewares = [middlewareStoreLogger, thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);
  const CombinedReducers = combineReducers({
    ...AppReducers
  });

  return legacy_createStore(CombinedReducers, composedEnhancers);
}

let store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApplicationRoot />
    </Provider>
  </React.StrictMode>
);
