import React from "react";
import { PanelWrapper } from "../components/common/panels/PanelWrapper";
import { FasterScaleInputPanel } from "../components/application/FasterScaleInputPanel/FasterScaleInputPanel";

export const ApplicationRoot = () => {

  return <PanelWrapper className="">
    <h1 className="p-3 text-3xl my-6 text-center"><a href="/">F.A.S.T.E.R.</a></h1>

    <PanelWrapper className="lg:w-2/4 md:container mx-auto bg-slate-300 lg:p-20 md:p-6 sm:p-2 rounded-xl">

      <FasterScaleInputPanel />
    </PanelWrapper>
  </PanelWrapper>
}